<template>
    <v-card>
        <v-row no-gutters>
            <v-col cols="11" class="d-flex flex-row" @click="$router.push({ name: 'Instructor Manage Course', params: { id: item.id, status: item.status.toLowerCase() } })">
                <section>
                    <v-img
                      v-if="item.image"
                      :src="item.image ? item.image.url : require('@/assets/images/course-placeholder-1.png')"
                        width="100"
                        height="100"
                        v-on:error="item.image = null"
                    >
                    </v-img>
                    <v-sheet 
                        :color="!item.image ? 'primary' : 'transparent'" 
                        class="hover border-b-l"
                        width="100"
                        height="100"
                        v-else>
                    </v-sheet>
                </section>
                <section class="mx-3 my-1">
                    <div class="poppins fw600 primary--text f10">
                        {{ item.status }}ED
                    </div>
                    <span class="poppins fw600 black--text text-left d-inline-block text-truncate" style="max-width: 35vw">
                        {{ item.title ? item.title : 'Untitled' }}
                    </span>
                    <div class="poppins fw400 secondary-3-text text-left f10">
                        {{ $dateFormat.fromNow(item.created_at) }}
                    </div>
                </section>
            </v-col>
            <v-col cols="1">
                <v-menu offset-y v-if="$route.name != 'Instructor Trash'">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            icon
                            v-bind="attrs"
                            v-on="on"
                            dense
                            class="float-right"
                            v-if="$isReadOnly.showOnlyToOwner(item.my_permission.length > 0 ? item.my_permission[0].pivot.permission : 'owner')"
                        >
                        <v-icon >
                            mdi-dots-vertical
                        </v-icon>
                        </v-btn>
                    </template>
                    <v-list>
                        <v-list-item @click="$router.push({ name: 'Instructor Manage Course', params: { id: item.id, status: item.status.toLowerCase() } })">
                          <v-list-item-title class="roboto f14 d-flex align-center">
                              <v-icon size="18" class="mr-2">
                                {{
                                  item.status === "PUBLISH"
                                  ? "mdi-eye-outline"
                                  : ($isReadOnly.isReadOnly(item.status, item.my_permission.length > 0 ? item.my_permission[0].pivot.permission : 'owner') ? 'mdi-eye-outline' : 'mdi-pencil')
                                }}
                              </v-icon>
                              <div class="">
                                {{
                                  item.status === "PUBLISH"
                                  ? "View"
                                  : ($isReadOnly.isReadOnly(item.status, item.my_permission.length > 0 ? item.my_permission[0].pivot.permission : 'owner') ? 'View' : 'Edit')
                                }}
                              </div>
                          </v-list-item-title>
                        </v-list-item>
                        <!-- <v-list-item @click="$router.push({ name: 'Instructor Manage Course', params: { id: item.id, status: item.status.toLowerCase() } })">
                            {{
                                item.status === "PUBLISH"
                                ? "View"
                                : ($isReadOnly.isReadOnly(item.status, item.my_permission.length > 0 ? item.my_permission[0].pivot.permission : 'owner') ? 'View' : 'Edit')
                            }}
                        </v-list-item> -->
                        <v-list-item @click="publish(item)" v-if="$isReadOnly.showCardAction(item.status, item.my_permission.length > 0 ? item.my_permission[0].pivot.permission : 'owner')">
                          <v-list-item-title class="roboto f14 d-flex align-center">
                              <v-icon size="18" class="mr-2">
                                {{
                                    item.status === "PUBLISH"
                                    ? "mdi-cancel"
                                    : item.status === "DRAFT"
                                    ? "mdi-publish"
                                    : "mdi-publish"
                                }}
                              </v-icon>
                              <div class="">
                                {{
                                    item.status === "PUBLISH"
                                    ? "Unpublish"
                                    : item.status === "DRAFT"
                                    ? "Publish"
                                    : "Publish"
                                }}
                              </div>
                          </v-list-item-title>
                        </v-list-item>
                        <v-list-item @click="moveToTrashDialog = true" v-if="$isReadOnly.showCardAction(item.status, item.my_permission.length > 0 ? item.my_permission[0].pivot.permission : 'owner')">
                          <v-list-item-title class="roboto f14 d-flex align-center">
                              <v-icon size="18" class="mr-2">mdi-trash-can-outline</v-icon>
                              <div class="">Move to Trash</div>
                          </v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu>
                <v-menu offset-y v-if="$route.name == 'Instructor Trash'">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            icon
                            v-bind="attrs"
                            v-on="on"
                            dense
                            class="float-right"
                            v-if="$isReadOnly.showOnlyToOwner(item.my_permission.length > 0 ? item.my_permission[0].pivot.permission : 'owner')"
                        >
                        <v-icon >
                            mdi-dots-vertical
                        </v-icon>
                        </v-btn>
                    </template>
                    <v-card width="150">
                      <v-list>
                          <v-list-item @click="restore(item.id)">
                              Restore Course
                          </v-list-item>
                          <v-list-item @click="deleteDialog =  true">
                              Delete Course
                          </v-list-item>
                      </v-list>
                    </v-card>
                </v-menu>
            </v-col>
        </v-row>
        <DeleteDialog
            :dialog="deleteDialog"
            :item="item"
            :course_id="item.id"
            type="Course"
            @close="deleteDialog = false"
        />

        <MoveToTrashDialog
            :dialog="moveToTrashDialog"
            :item="item"
            :course_id="item.id"
            type="Course"
            @close="() => { moveToTrashDialog = false, $emit('fetchCourses') }"
            @moveToTrash="moveToTrash"
        />
    </v-card>
</template>

<script>

import DeleteDialog from "./DeleteDialog.vue"
import MoveToTrashDialog from "./MoveToTrashDialog.vue";
import { mapMutations, mapActions } from "vuex";
export default {
  props: ["i", "item", "gallery"],
  data : () => ({
    moveToTrashDialog: false,
    deleteDialog: false,
    asset_path: `${process.env.VUE_APP_ASSET_PATH}`,
    defaultImage: `${process.env.VUE_APP_ASSET_PATH}/course-placeholder-1.png`
  }),
  components: {
    MoveToTrashDialog,
    DeleteDialog
  },
  methods: {
    ...mapMutations("instructor", ["statusCourseMutation", "alertMutation"]),
    ...mapActions("instructor", ["restoreCourseAction", "addToTrashAction", "getCoursesAction", "getTrashAction", "getInstructorAssessmentsAction"]),

    error(){
      this.alertMutation({
        show: true,
        text: 'Something went wrong.',
        type: "error"
      })
    },
    
    publish(data) {
      if (data.status == "PUBLISH") {
        this.statusCourseMutation({
          id: data.id,
          status: true,
          action: 'unpublish'
        });
      } else {
        this.statusCourseMutation({
          id: data.id,
          status: true,
          action: 'publish'
        });
      }
    },

    restore(course_id) {
      this.restoreCourseAction({ course_id: course_id }).then(()=>{
        this.getTrashAction().then(() => {
          this.getCoursesAction().then(() => {
            this.getInstructorAssessmentsAction().then(() => {
              this.$emit('showRestoreSuccess')
            }).catch(() => {
              this.error()
            })
          }).catch(() => {
            this.error()
          })
        }).catch(() => {
          this.error()
        })
      }).catch(() => {
        this.error()
      })
    },

    moveToTrash(course_id){
      this.moveToTrashAction({ course_id: course_id }).then(()=>{
        this.getTrashAction().then(() => {
          this.getCoursesAction().then(() => {
            this.getInstructorAssessmentsAction().then(() => {
            }).catch(() => {
              this.error()
            })
          }).catch(() => {
            this.error()
          })
        }).catch(() => {
          this.error()
        })
      }).catch(() => {
        this.error()
      })
    }
  },

  computed: {
    getPrimary() {
      return this.$vuetify.theme.themes.light.primary
    }
  }
};
</script>