<template>
  <v-card class="custom-border d-flex flex-column justify-space-between" :width="gallery ? 250 : '100%'">
    <v-sheet 
      :color="!item.image ? 'primary' : 'transparent'" 
      :style="gallery && 'height: 150px'" 
      class="hover border-top"
      v-if="gallery">
      <v-img
        v-if="item.image && gallery"
        :src="item.image ? item.image.url : require('@/assets/images/course-placeholder-1.png')"
        :style="$vuetify.breakpoint.mobile ? gallery ? 'height: 150px; width: 100%' : 'height: 40px; width: 100%':gallery ? 'height: 150px; width: 100%' : 'height: 40px; width: 100%'"
        class="border-top roboto f14"
        v-on:error="item.image = null"
      >
      </v-img>
    </v-sheet>
    <section v-if="gallery" class="rounded-b">
        <v-row dense class="pa-2">
          <v-col cols="11">
            <div class="poppins fw600 primary--text text-left f10">
                {{ item.status }}ED
            </div>
            <span v-if="gallery" class="poppins fw600 black--text text-left d-inline-block text-truncate" style="max-width: 90%">
              {{ item.title ? item.title : 'Untitled' }}
            </span>
            <span v-else-if="!gallery" class="poppins fw600 black--text text-left text-wrap">
              {{ item.title ? item.title : 'Untitled' }}
            </span>
            <div class="poppins fw400 secondary-3-text text-left f10">
              {{ $dateFormat.fromNow(item.created_at) }}
            </div>
          </v-col>
          <v-col cols="1">
            <v-menu offset-y v-if="$route.name == 'Instructor Trash'">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  class="float-right"
                  v-bind="attrs"
                  v-on="on"
                  :class="!gallery && 'ml-auto'"
                >
                  <v-icon color="secondary">
                    mdi-dots-vertical
                  </v-icon>
                </v-btn>
              </template>
                <v-list>
                  <v-list-item @click="restore(item.id)">
                    Restore
                  </v-list-item>
                </v-list>
            </v-menu>
            <v-menu offset-y v-if="$route.name != 'Instructor Trash'">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  class="float-right"
                  v-bind="attrs"
                  v-on="on"
                  :class="!gallery && 'ml-auto'"
                  v-if="$isReadOnly.showOnlyToOwner(item.my_permission.length > 0 ? item.my_permission[0].pivot.permission : 'owner')"
                >
                  <v-icon >
                    mdi-dots-vertical
                  </v-icon>
                </v-btn>
              </template>
              <v-list class="f14">
                <v-list-item  v-if="!gallery" @click="publish(item)">
                  {{
                    item.status === "PUBLISH"
                      ? "UNPUBLISH"
                      : item.status === "DRAFT"
                      ? "PUBLISH"
                      : "PUBLISH"
                  }}
                </v-list-item>
                <v-list-item @click="moveToTrashDialog = true">
                  <v-list-item-title class="roboto f14 d-flex align-center">
                      <v-icon size="18" class="mr-2">mdi-trash-can-outline</v-icon>
                      <div class="">Move to Trash</div>
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-col>
        </v-row>
      <v-row no-gutters v-if="gallery">
        <v-col :cols="$isReadOnly.showCardAction(item.status, item.my_permission.length > 0 ? item.my_permission[0].pivot.permission : 'owner') ? 6: 12" class="d-flex justify-center">
          <v-btn
            block
            color="secondary-5"
            class="poppins f10 fw500"
            dense
            @click="
              $router.push({ name: 'Instructor Manage Course', params: { id: item.id, status: item.status.toLowerCase() } })
            "
            tile
            :class="$isReadOnly.showCardAction(item.status, item.my_permission.length > 0 ? item.my_permission[0].pivot.permission : 'owner') ? 'rounded-bl' : 'rounded-b'"
            v-if="$route.name != 'Instructor Trash'"
          >
          {{
            item.status === "PUBLISH"
              ? "View"
              : ($isReadOnly.isReadOnly(item.status, item.my_permission.length > 0 ? item.my_permission[0].pivot.permission : 'owner') ? 'View' : 'Edit')
          }}
          </v-btn>
          <v-btn
            v-else
            block
            dense
            color="secondary-5"
            class="poppins f10 fw500 rounded-bl"
            tile
            @click="deleteDialog =  true"
          >
            Delete
          </v-btn>
        </v-col>
        <v-col cols="6" class="d-flex justify-center" v-if="$isReadOnly.showCardAction(item.status, item.my_permission.length > 0 ? item.my_permission[0].pivot.permission : 'owner')">
          <v-btn
            dense
            block
            tile
            color="primary"
            class="fw500 poppins f10 "
            :class="$isReadOnly.showCardAction(item.status, item.my_permission.length > 0 ? item.my_permission[0].pivot.permission : 'owner') ? 'rounded-br' : 'rounded-b'"
            @click="publish(item)"
            v-if="$route.name != 'Instructor Trash'"
          >
            {{
              item.status === "PUBLISH"
                ? "Unpublish"
                : item.status === "DRAFT"
                ? "&nbsp; Publish &nbsp;"
                : "&nbsp; Publish &nbsp;"
            }}
          </v-btn>
          <v-btn
            dense
            block
            tile
            color="primary poppins f10 fw500 rounded-br"
            class="fw500 poppins f10"
            v-if="$route.name == 'Instructor Trash'"
            @click="restore(item.id)"
          >
            Restore
          </v-btn>
        </v-col>
      </v-row>
    </section>
    <DeleteDialog
      :dialog="deleteDialog"
      :item="item"
      :course_id="item.id"
      type="Course"
      @close="deleteDialog = false"
    />

    <MoveToTrashDialog
      :dialog="moveToTrashDialog"
      :item="item"
      :course_id="item.id"
      type="Course"
      @close="() => { moveToTrashDialog = false, $emit('fetchCourses') }"
      @moveToTrash="moveToTrash"
    />
  </v-card>
</template>

<script>
import DeleteDialog from "./DeleteDialog.vue"
import MoveToTrashDialog from "./MoveToTrashDialog.vue";
import { mapMutations, mapActions } from "vuex";
export default {
  props: ["i", "item", "gallery"],
  data : () => ({
    moveToTrashDialog: false,
    deleteDialog: false,
    asset_path: `${process.env.VUE_APP_ASSET_PATH}`,
    defaultImage: `${process.env.VUE_APP_ASSET_PATH}/course-placeholder-1.png`
  }),
  components: {
    MoveToTrashDialog,
    DeleteDialog
  },
  methods: {
    ...mapMutations("instructor", ["statusCourseMutation", "alertMutation"]),
    ...mapActions("instructor", ["restoreCourseAction", "addToTrashAction", "getCoursesAction", "getTrashAction", "getInstructorAssessmentsAction"]),

    error(){
      this.alertMutation({
        show: true,
        text: 'Something went wrong.',
        type: "error"
      })
    },

    publish(data) {
      if (data.status == "PUBLISH") {
        this.statusCourseMutation({
          id: data.id,
          status: true,
          action: 'unpublish'
        });
      } else {
        this.statusCourseMutation({
          id: data.id,
          status: true,
          action: 'publish'
        });
      }
    },

    restore(course_id) {
      this.restoreCourseAction({ course_id: course_id }).then(()=>{
        this.getTrashAction().then(() => {
          this.getCoursesAction().then(() => {
            this.getInstructorAssessmentsAction().then(() => {
              this.$emit('showRestoreSuccess')
            }).catch(() => {
              this.error()
            })
          }).catch(() => {
            this.error()
          })
        }).catch(() => {
          this.error()
        })
      }).catch(() => {
        this.error()
      })
    },

    moveToTrash(course_id){
      this.moveToTrashAction({ course_id: course_id }).then(()=>{
        this.getTrashAction().then(() => {
          this.getCoursesAction().then(() => {
            this.getInstructorAssessmentsAction().then(() => {
            }).catch(() => {
              this.error()
            })
          }).catch(() => {
            this.error()
          })
        }).catch(() => {
          this.error()
        })
      }).catch(() => {
        this.error()
      })
    }
  },

  computed: {
    getPrimary() {
      return this.$vuetify.theme.themes.light.primary
    }
  }
};
</script>
